import {
  FETCH_ACCESS_LEVELS,
  FETCH_ACCESS_LEVELS_SUCCESS,
  FETCH_ACCESS_LEVELS_ERROR,
  AccessLevelsActionTypes,
} from './types';

export const fetchAccessLevels = (): AccessLevelsActionTypes => ({
  type: FETCH_ACCESS_LEVELS,
});

export const fetchAccessLevelsSuccess = (data: any[]): AccessLevelsActionTypes => ({
  type: FETCH_ACCESS_LEVELS_SUCCESS,
  payload: data,
});

export const fetchAccessLevelsError = (error: string): AccessLevelsActionTypes => ({
  type: FETCH_ACCESS_LEVELS_ERROR,
  payload: error,
});

export const GetAccessLevels = () => fetchAccessLevels(); 