import { combineReducers } from 'redux';
import { SidebarReducer } from './sidebar/reducer';
import { AuthReducer } from './auth/reducer';
import { UsersReducer } from './users/reducer';
import { SubscriptionsReducer } from './subscriptions/reducer';
import { AppsReducer } from './apps/reducer';
import { BookmarksReducer } from './bookmarks/reducer';
import { SchollReducer } from './scholl/reducer';

import { ProductsReducer } from './products/reducer';
import { SupportReducer } from './support/reducer';
import { ActionsReducer } from './actions/reducer';
import { UsersAdminReducer } from './usersAdmin/reducer';
import { ProductActionsReducer } from './productActions/reducer';
import { accessLevelsReducer } from './accessLevels/reducer';

const rootReducer = combineReducers({
  Sidebar: SidebarReducer,
  Auth: AuthReducer,
  Users: UsersReducer,
  Subscriptions: SubscriptionsReducer,
  Products: ProductsReducer,
  Support: SupportReducer,
  Actions: ActionsReducer,
  Apps: AppsReducer,
  UsersAdmin: UsersAdminReducer,
  ProductActions: ProductActionsReducer,
  Bookmarks: BookmarksReducer,
  Scholl: SchollReducer,
  AccessLevels: accessLevelsReducer,
});

export default rootReducer;
