import { fork } from 'redux-saga/effects';
import authSaga from './auth/sagas';
import usersSaga from './users/sagas';
import subscriptionsSaga from './subscriptions/sagas';
import productsSaga from './products/sagas';
import supportSaga from './support/sagas';
import actionsSaga from './actions/sagas';
import appsSaga from './apps/sagas';
import bookmarksSaga from './bookmarks/sagas';
import schollsSaga from './scholl/sagas';

import usersAdminSaga from './usersAdmin/sagas';
import productActionsSaga from './productActions/sagas';
import accessLevelsSaga from './accessLevels/sagas';

export default function* Main() {
  yield fork(authSaga); 
  yield fork(usersSaga);
  yield fork(subscriptionsSaga);
  yield fork(bookmarksSaga);
  yield fork(schollsSaga);
  yield fork(productsSaga);
  yield fork(supportSaga);
  yield fork(actionsSaga);
  yield fork(appsSaga);
  yield fork(usersAdminSaga);
  yield fork(productActionsSaga);
  yield fork(accessLevelsSaga);
}
