export interface IAccessLevel {
  value: number;
  label: string;
}

export interface IAccessLevelsState {
  data: IAccessLevel[];
  loading: boolean;
  error: string | null;
}

export const FETCH_ACCESS_LEVELS = 'FETCH_ACCESS_LEVELS';
export const FETCH_ACCESS_LEVELS_SUCCESS = 'FETCH_ACCESS_LEVELS_SUCCESS';
export const FETCH_ACCESS_LEVELS_ERROR = 'FETCH_ACCESS_LEVELS_ERROR';

export interface FetchAccessLevelsAction {
  type: typeof FETCH_ACCESS_LEVELS;
}

export interface FetchAccessLevelsSuccessAction {
  type: typeof FETCH_ACCESS_LEVELS_SUCCESS;
  payload: IAccessLevel[];
}

export interface FetchAccessLevelsErrorAction {
  type: typeof FETCH_ACCESS_LEVELS_ERROR;
  payload: string;
}

export type AccessLevelsActionTypes =
  | FetchAccessLevelsAction
  | FetchAccessLevelsSuccessAction
  | FetchAccessLevelsErrorAction; 