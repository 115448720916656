import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Alert, AlertTitle } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { StylesEdit } from '../StylesEdit';
import { IProduct } from '../../store/products/types';
import { UpdateProduct } from '../../store/products/actions';
import {SelectOutline} from "../ui";
import {typeSubscriptions} from "../../utils/consts";
import { IAccessLevel } from '../../store/accessLevels/types';

interface PropsType {
  titlePage?: string;
  data?: IProduct | null;
  parentId: number;
  handleCancel: () => void;
  AccessLevels?: IAccessLevel[];
}

export const EditProducts: React.FC<PropsType> = ({ titlePage, data, handleCancel, AccessLevels }) => {
  const [name, setName] = React.useState<string>(data?.name || '');
  const [subName, setSubName] = React.useState<string>(data?.subName || '');
  const [url, setUrl] = React.useState<string>(data?.url || '');
  const [error, setError] = React.useState(false);
  const currentTypeSubscribe = typeSubscriptions.find((item) => item.label === data?.typeSubscribe);
  const [typeSubscribe, setTypeSubscribe] = React.useState<string>(currentTypeSubscribe?.value || '');
  const [accessLevel, setAccessLevel] = React.useState<string>(data?.accessLevelId?.toString() || '');
  const { Products } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classes = StylesEdit();

  const accessLevelOptions = AccessLevels?.map(level => ({
    value: level.value.toString(),
    label: level.label
  })) || [];

  const onSubmit = () => {
    if (!data) return null;
    if (!name || !subName) return setError(true);
    const typeSubscribeValue = typeSubscriptions.find((item) => item.value === typeSubscribe)?.label || '';
    dispatch(
      UpdateProduct.request({
        id: data.absnum,
        data: {
          name,
          subName,
          url,
          typeSubscribe: typeSubscribeValue,
          accessLevelId: accessLevel ? parseInt(accessLevel) : undefined,
        },
        callBack: (succ) => succ && handleCancel(),
      })
    );
  };

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper}>
        <Box className={classes.header}>
          <Typography component="h1" variant="h5">
            {titlePage}
          </Typography>
        </Box>
        <TextField
          style={{ marginTop: '20px' }}
          required
          size="small"
          id="outlined-basic"
          label="Назва продукту"
          variant="outlined"
          value={name}
          error={error && !name}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setName(event.target.value as string);
          }}
        />
        <TextField
          style={{ marginTop: '20px' }}
          required
          size="small"
          id="outlined-basic-2"
          label="Назва для виводу"
          variant="outlined"
          value={subName}
          error={error && !subName}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSubName(event.target.value as string);
          }}
        />
        <TextField
          style={{ marginTop: '20px' }}
          size="small"
          id="outlined-basic-3"
          label="URL"
          variant="outlined"
          value={url}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setUrl(event.target.value as string);
          }}
        />

        <SelectOutline
          style={{ marginTop: '20px' }}
          className={classes.textField}
          title="Тип підписки"
          options={typeSubscriptions}
          handleChange={(value) => setTypeSubscribe(value)}
          value={typeSubscribe}
        />

        {
          AccessLevels && 
          <SelectOutline
            style={{ marginTop: '20px' }}
            className={classes.textField}
            title="Рівень доступу"
            options={accessLevelOptions}
            handleChange={(value) => setAccessLevel(value)}
            value={accessLevel}
            error={!AccessLevels}
            clearable={true}
          />
        }
        <Box style={{ margin: '20px 0' }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={Products.loading}
            onClick={onSubmit}
            style={{ height: '36px', marginRight: '40px' }}
          >
            {Products.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant="outlined" className={classes.submit} onClick={handleCancel} style={{ height: '36px' }}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity="error">
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {Products.errors ? (
          <Alert severity="error">
            <AlertTitle>Сталася помилка</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
