import { FormControl, InputLabel, MenuItem, Select, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface SelectProps {
  id?: string;
  error?: boolean;
  required?: boolean;
  options: { value: string; label: string }[];
  title: string;
  handleChange: (value: string) => void;
  value: string;
  style?: React.CSSProperties | undefined;
  className?: string;
  clearable?: boolean;
}

export const SelectOutline: React.FC<SelectProps> = ({ 
  id, 
  style, 
  className, 
  options, 
  title, 
  handleChange, 
  value, 
  required, 
  error,
  clearable 
}) => {
  return (
    <Box style={style} className={className}>
      <FormControl fullWidth required={required} error={error} size="small" variant="outlined">
        <InputLabel id={`${id}-label`}>{title}</InputLabel>
        <Select 
          labelId={`${id}-label`} 
          id={id} 
          value={value} 
          label={title}
          endAdornment={
            clearable && value ? (
              <IconButton 
                size="small" 
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange('');
                }}
                sx={{ color: 'error.main', mr: 1 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ) : null
          }
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} onClick={() => handleChange(option.value)}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
