import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { StylesTable } from '../Stylestable';
import { IProduct } from '../../store/products/types';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { EnhancedTableHead } from '../ui';
import { Order, stableSort } from '../../utils/stableSort';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { EditProducts } from './EditProducts';
import roles from '../../utils/roles';
import { IAccessLevel } from '../../store/accessLevels/types';

interface Column {
  id: 'absnum' | 'subName' | 'name' | 'url' | 'edit' | 'typeSubscribe' | 'accessLevelId';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  sort?: boolean;
  roles?: number[];
}

const sort = true;

const columns: Column[] = [
  { id: 'absnum', label: 'ID', align: 'center', width: '5%', sort },
  { id: 'name', label: 'Версія продукту', align: 'left', sort },
  { id: 'subName', label: 'Назва для виводу', align: 'left', sort },
  { id: 'url', label: 'URL', align: 'left' },
  { id: 'typeSubscribe', label: 'Type Subscribe', align: 'left' },
  { id: 'accessLevelId', label: 'Access Level', align: 'left' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '10%',
    roles: [roles.ADMIN, roles.MARKETER],
  },
];

type PropsType = {
  product: IProduct;
  handleBack: () => void;
  AccessLevels: IAccessLevel[];
};

export const ListSubProducts: React.FC<PropsType> = ({ product, handleBack, AccessLevels }) => {
  console.log('AccessLevels==>', AccessLevels);

  const [editData, setEditData] = React.useState<{ data?: IProduct | null } | null>(null);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof IProduct>('absnum');

  const { Auth } = useSelector((store: AppStore) => store);

  const classesTable = StylesTable();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IProduct) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (editData) {
    return (
      <EditProducts titlePage={'Редагувати версію продукту'} data={editData.data} parentId={product.absnum} handleCancel={() => setEditData(null)} AccessLevels={AccessLevels}/>
    );
  }

  return (
    <>
      <Box>
        <Typography component="h1" variant="h5" style={{ margin: '0 7px 20px 7px' }}>
        Головний продукт {` ${product.absnum}, ${product.name}`}
        </Typography>
        <Button style={{ margin: '0 20px 10px 7px' }} variant="outlined" color="primary" onClick={handleBack}>
          Повернутися
        </Button>
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label="sticky table">
          {Auth.data?.rights ? (
            <EnhancedTableHead order={order} orderBy={orderBy} columns={columns} rights={Auth.data?.rights} onRequestSort={handleRequestSort} />
          ) : null}
          <TableBody>
            {product.children &&
              stableSort(product.children, order, orderBy).map((row, i) => {
                return (
                  <TableRow style={{ maxHeight: 50 }} hover role="checkbox" tabIndex={-1} key={`${i}-${row.absnum}`}>
                    <TableCell align="center">{row.absnum}</TableCell>
                    <TableCell className={classesTable.row}>{row.name}</TableCell>
                    <TableCell className={classesTable.row}>{row.subName}</TableCell>
                    <TableCell className={classesTable.row}>{row.url}</TableCell>
                    <TableCell className={classesTable.row}>{row?.typeSubscribe || ''}</TableCell>
                    <TableCell className={classesTable.row}>
                      {(row?.accessLevelId || row?.accessLevelId === 0) ? AccessLevels.find(level => level.value === row.accessLevelId)?.label : ''}
                    </TableCell>
                    {Auth.data?.rights[roles.ADMIN] || Auth.data?.rights[roles.MARKETER] ? (
                      <TableCell align="center">
                        <IconButton aria-label="edit" onClick={() => setEditData({ data: row })}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
