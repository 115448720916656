export interface TProductsState {
  data: {
    ip?: string; products: IProduct[]; currentPage: number; pages: number 
} | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IProduct {
  absnum: number;
  subName: string;
  name: string;
  parentId: number;
  approved: number;
  url: string;
  children: IProduct[];
  typeSubscribe?: string;
  accessLevelId?: number;
}

enum ActionTypes {
  GET_PRODUCTS_R = '@@products/GET_PRODUCTS_R',
  GET_PRODUCTS_S = '@@products/GET_PRODUCTS_S',
  GET_PRODUCTS_E = '@@products/GET_PRODUCTS_E',

  SEARCH_PRODUCTS_R = '@@products/SEARCH_PRODUCTS_R',
  SEARCH_PRODUCTS_S = '@@products/SEARCH_PRODUCTS_S',
  SEARCH_PRODUCTS_E = '@@products/SEARCH_PRODUCTS_E',

  UPDATE_PRODUCT_R = '@@products/UPDATE_PRODUCT_R',
  UPDATE_PRODUCT_S = '@@products/UPDATE_PRODUCT_S',
  UPDATE_PRODUCT_E = '@@products/UPDATE_PRODUCT_E',
}

export default ActionTypes;
