import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import { GetProducts, SearchProducts, TypeGetProductsR, TypeSearchProductsR, TypeUpdateProductR, UpdateProduct } from './actions';
import ActionTypes, { IProduct, TProductsState } from './types';

function* getProductsWorker(action: ReturnType<typeof GetProducts.request>): Generator {
  const { data, callBack } = action.payload as TypeGetProductsR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: query ? `/products?${query}` : '/products',
    })) as TProductsState['data'];
    yield put(GetProducts.success(resp));
  } catch (e) {
    success = false;
    yield put(GetProducts.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* searchProductsWorker(action: ReturnType<typeof SearchProducts.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchProductsR;

  let success = true;
  const query = buildQuery(data);
  let resp = null as TProductsState['data'];
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: query ? `/products?${query}` : '/products',
    })) as TProductsState['data'];
  } catch (e) {
    success = false;
    yield put(SearchProducts.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp?.products);
  }
}

function* updateProductWorker(action: ReturnType<typeof UpdateProduct.request>): Generator {
  const { id, data, callBack } = action.payload as TypeUpdateProductR;
  let success = true;

  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      path: `/products/${id}`,
      data,
    })) as IProduct;
    yield put(UpdateProduct.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateProduct.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_PRODUCTS_R, getProductsWorker);
  yield takeEvery(ActionTypes.SEARCH_PRODUCTS_R, searchProductsWorker);
  yield takeEvery(ActionTypes.UPDATE_PRODUCT_R, updateProductWorker);
}

export default function* productsSaga() {
  yield all([fork(watchFetchRequest)]);
}
