import {
  FETCH_ACCESS_LEVELS,
  FETCH_ACCESS_LEVELS_SUCCESS,
  FETCH_ACCESS_LEVELS_ERROR,
  IAccessLevelsState,
  AccessLevelsActionTypes,
} from './types';

const initialState: IAccessLevelsState = {
  data: [],
  loading: false,
  error: null,
};

export const accessLevelsReducer = (
  state = initialState,
  action: AccessLevelsActionTypes
): IAccessLevelsState => {
  switch (action.type) {
    case FETCH_ACCESS_LEVELS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ACCESS_LEVELS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_ACCESS_LEVELS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}; 