import { call, put, takeLatest } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import {
  FETCH_ACCESS_LEVELS,
  FETCH_ACCESS_LEVELS_SUCCESS,
  FETCH_ACCESS_LEVELS_ERROR,
} from './types';

function* fetchAccessLevelsSaga(): Generator<any, void, any> {
  try {
    const response = yield call(callApi, {
      method: 'get',
      path: '/v1/access-levels',
    });
    yield put({ type: FETCH_ACCESS_LEVELS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ 
      type: FETCH_ACCESS_LEVELS_ERROR, 
      payload: error instanceof Error ? error.message : 'An error occurred' 
    });
  }
}

export default function* accessLevelsSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_ACCESS_LEVELS, fetchAccessLevelsSaga);
} 